<template>
  <div class="home-mb">
    <div class="home-mb__header">
      <slot name="header" />
    </div>
    <div
      class="home-mb__main"
    >
      <div
        id="home"
        class="home-mb__main"
      >
        <slot name="main" />
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/index.scss"></style>
